import { memo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { externalLinkMap } from '../../../constants';

import './index.scss';

export const Footer = memo(function () {
  return (
    <footer>
      <Container className="goa-footer" fluid>
        <Row>
          <a
            className="goa-footer-link center"
            href={externalLinkMap.gameOfApps}
            target="_blank"
            rel="noopener noreferrer"
          >
            Back to Game of Apps Official Site
          </a>
        </Row>
      </Container>
    </footer>
  );
});
